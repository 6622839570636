<script setup>
import { ref, defineEmits, computed, watch } from 'vue';
import { event } from '../../config.js';

import OfModal from 'of_vue_utils/src/utils/OfModal.vue';
import OfInput from 'of_vue_utils/src/utils/OfInput.vue';
import OfWidget from 'of_vue_utils/src/utils/OfWidget.vue';
import OfTable from 'of_vue_utils/src/utils/OfTable.vue';
import OfDivider from 'of_vue_utils/src/utils/OfDivider.vue';
import OfButton from 'of_vue_utils/src/utils/OfButton.vue';

import requests from '../../OfEventRequests';
import { phrasePaymentMethodType, phraseSource, phraseChannel, phraseContactType, enforceRule, requestedAnonymized } from '../../functions.js';

const newBusinessPayment = ref({
    amount: null,
    businessCode: null,
    companyName: null,
    displayName: "",
    email: null,
    paymentMethodType: null,
    showOnIndividualCrawler: false,
    showOnBusinessCrawler: false,
    amountOriginal: computed(() => newBusinessPayment.value ? Number(newBusinessPayment.value.amount) : 0),
    source: 'MANUAL',
    state: "APPROVED"
})

const contactTypeOptions = ref([{ slug: 'INDIVIDUAL', name: phraseContactType('INDIVIDUAL') }, { slug: 'BUSINESS', name: phraseContactType('BUSINESS') }])

const paymentMethodOptions = ref([{ slug: 'CARD', name: phrasePaymentMethodType('CARD') }, { slug: 'INVOICE', name: phrasePaymentMethodType('INVOICE') }])

const emit = defineEmits(['close', 'reload']);

const invalidEmail = computed(() => {
    if (newBusinessPayment.value.email) {
        const rx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        return !rx.test(newBusinessPayment.value.email)
    }
    return false;

})
const displayNameAvailable = computed(() => {
    if (event.value.showOnlyPaymentsByBusinessesOnBusinessCrawler) {
        return newBusinessPayment.value.contactType == 'BUSINESS';
    }
    else {
        return true;
    }
});
const displayMessageAvailable = computed(() => {
    if (event.value.showOnlyPaymentsByBusinessesOnBusinessCrawler) {
        return newBusinessPayment.value.contactType == 'INDIVIDUAL';
    }
    else {
        return false;
    }
});
watch(() => newBusinessPayment.value.contactType, () => {
    if (event.value.showOnlyPaymentsByBusinessesOnBusinessCrawler) {
        if (newBusinessPayment.value.contactType == 'INDIVIDUAL') {
            newBusinessPayment.value.showOnBusinessCrawler = false;
        } else if (newBusinessPayment.value.contactType == 'BUSINESS') {
            newBusinessPayment.value.showOnIndividualCrawler = false;
        }
    }
})
function save() {
    var form = document.getElementById('create-business-payment');

    if (!form.checkValidity()) {
        var tmpSubmit = document.createElement('button')
        form.appendChild(tmpSubmit)
        tmpSubmit.click()
        form.removeChild(tmpSubmit)
    } else if (invalidEmail.value) {
        alert('Ugyldig email')
    } else if (newBusinessPayment.value.amount < event.value.businessPaymentThreshold) {
        alert('Ugyldigt beløb')
    } else {

        if (!newBusinessPayment.value.displayName) {
            newBusinessPayment.value.displayName = 'Ikke oplyst';
        }

        requests.post.businessPayment(newBusinessPayment.value).then((result) => {
            if (result) {
                emit('reload');
                emit('close');
            }
        })
    }
}
</script>

<template>
    <form id="create-business-payment" @submit.prevent>
        <OfModal submit-text="Tilføj" @close="emit('close')">
            <template #heading>
                Tilføj donation
            </template>

            <template #left>
                <OfWidget class="widget-left">
                    <template #content>
                        <OfInput v-model="newBusinessPayment.contactType" type="select" label="Kontakttype"
                            placeholder="Kontakttype" :options="contactTypeOptions" required />

                        <OfInput v-model="newBusinessPayment.paymentMethodType" type="select" label="Betalingsmetode"
                            placeholder="Betalingsmetode" :options="paymentMethodOptions" null-option="Ikke oplyst" />

                        <OfInput v-if="newBusinessPayment.contactType == 'BUSINESS'"
                            v-model="newBusinessPayment.businessCode" type="tel" label="CVR-nr." placeholder="CVR-nr."
                            :minlength="8" @keydown="enforceRule('digits', $event)" />

                        <OfInput v-if="newBusinessPayment.contactType == 'BUSINESS'"
                            v-model="newBusinessPayment.companyName" type="text" label="Firmanavn"
                            placeholder="Firmanavn" />

                        <OfInput v-model="newBusinessPayment.msisdn" type="tel" label="Tlf.nr." placeholder="Tlf.nr."
                            :minlength="8" :maxlength="14" @keydown="enforceRule('digits', $event)" />

                        <OfInput v-model="newBusinessPayment.email" type="email" label="Email" placeholder="Email" />

                        <p v-if="invalidEmail" class="property-warning">Ugyldig email</p>

                        <OfInput v-model="newBusinessPayment.address" type="text" label="Adresse" placeholder="Adresse" />

                        <OfInput v-model="newBusinessPayment.postCode" type="tel" label="Postnr." placeholder="Postnr."
                            @keydown="enforceRule('digits', $event)" />

                        <OfInput v-model="newBusinessPayment.city" type="tel" label="By" placeholder="By" />


                    </template>

                </OfWidget>

            </template>

            <template #right>
                <OfWidget class="widget-right">
                    <template #content>
                        <OfTable type="widgetinfo">
                            <template #tbody>

                                <tr>
                                    <td>Kilde</td>
                                    <td>
                                        {{ phraseSource(newBusinessPayment.source) }}
                                    </td>
                                </tr>

                                <tr>
                                    <td>Kanal</td>
                                    <td>
                                        {{ phraseChannel(newBusinessPayment.channel) }}
                                    </td>
                                </tr>

                                <tr>
                                    <td>Originalt beløb</td>
                                    <td>
                                        {{ newBusinessPayment.amountOriginal.toLocaleString('da-DK',
                                            { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}
                                    </td>
                                </tr>

                            </template>
                        </OfTable>

                        <OfDivider direction="horizontal" />

                        <div class="inputs">
                            <p v-if="requestedAnonymized(newBusinessPayment)" class="requested-anonymized">
                                Ønskes ikke vist på TV
                            </p>

                            <OfInput v-else-if="displayNameAvailable" v-model="newBusinessPayment.showOnBusinessCrawler"
                                name="showOnBusinessCrawler" type="checkbox" label="Vis på TV" />

                            <OfInput v-else-if="displayMessageAvailable"
                                v-model="newBusinessPayment.showOnIndividualCrawler" name="showOnIndividualCrawler"
                                type="checkbox" label="Vis seeropfordring på TV" />

                            <OfInput v-model="newBusinessPayment.amount" type="tel" label="Beløb" placeholder="Beløb"
                                :maxlength="8" required @keydown="enforceRule('number', $event)" />

                            <p v-if="newBusinessPayment.amount < event.businessPaymentThreshold" class="property-warning">
                                Minimum {{ event.businessPaymentThreshold }}</p>


                            <OfInput v-if="newBusinessPayment.showOnBusinessCrawler"
                                v-model="newBusinessPayment.displayName" type="text" label="Visningsnavn"
                                placeholder="Visningsnavn" :maxlength="event.maxCrawlerDisplayNameLength" required />

                            <OfInput v-if="newBusinessPayment.showOnIndividualCrawler && displayMessageAvailable"
                                v-model="newBusinessPayment.displayMessage" type="text" label="Seeropfordring"
                                placeholder="Seeropfordring" :maxlength="event.maxCrawlerDisplayMessageLength" required />

                            <OfDivider direction="horizontal" />

                            <OfInput v-model="newBusinessPayment.comment" type="textarea" label="Intern note" />
                        </div>

                    </template>
                </OfWidget>

                <div class="buttons">
                    <OfButton text="Opret" coloring="secondary" @click="save()" />
                </div>

            </template>
        </OfModal>
    </form>
</template>
<style scoped>
.widget-left,
.widget-right {
    margin-top: 1rem;
}

.buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
}

.requested-anonymized {
    margin-bottom: 1rem;
    font-size: 14px;
}

.widget-left {
    padding: 1rem;
}

.widget-right :deep(.of-widget-table-info tr > td:first-child) {
    width: 36%;
}

.widget-right :deep(.divider) {
    margin: 0;
}

.widget-right .inputs {
    padding: 1rem;
    padding-left: 30px;
}

.widget-right .inputs>:deep(.row:last-of-type) {
    margin-top: 1rem;
}

.property-warning {
    margin-top: -5px;
    display: block;
    width: 100%;
    position: relative;
    text-align: right;
    margin-bottom: 1rem;
    color: #E6492D;
    text-decoration: none;
}
</style>